export const MESSAGE_LIST_REQUEST = 'MESSAGE_LIST_REQUEST'
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS'
export const MESSAGE_LIST_FAIL = 'MESSAGE_LIST_FAIL'
export const MESSAGE_LIST_RESET = 'MESSAGE_LIST_RESET'

export const MESSAGE_DETAILS_REQUEST = 'MESSAGE_DETAILS_REQUEST'
export const MESSAGE_DETAILS_SUCCESS = 'MESSAGE_DETAILS_SUCCESS'
export const MESSAGE_DETAILS_FAIL = 'MESSAGE_DETAILS_FAIL'
export const MESSAGE_DETAILS_RESET = 'MESSAGE_DETAILS_RESET'

export const MESSAGE_DELETE_REQUEST = 'MESSAGE_DELETE_REQUEST'
export const MESSAGE_DELETE_SUCCESS = 'MESSAGE_DELETE_SUCCESS'
export const MESSAGE_DELETE_FAIL = 'MESSAGE_DELETE_FAIL'

export const MESSAGE_CREATE_REQUEST = 'MESSAGE_CREATE_REQUEST'
export const MESSAGE_CREATE_SUCCESS = 'MESSAGE_CREATE_SUCCESS'
export const MESSAGE_CREATE_FAIL = 'MESSAGE_CREATE_FAIL'
export const MESSAGE_CREATE_RESET = 'MESSAGE_CREATE_RESET'
